<template>
  <div
    v-if="renderCameras && getCameras.length"
    class="cameras-container"
    :class="{ fullscreen: fullScreen }"
  >
    <div
      class="pl-2 pr-2"
      style="
        align-self: end;
        text-align: end;
        border-bottom: 1px solid dimgrey;
        padding: 3px;
        width: 100%;
        background-color: #1e1e1e;
      "
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div style="display: inline" @click="forceRerender">
            <v-icon size="20" dark v-on="on" class="cursor-pointer ml-3" style="color: white">
              mdi-refresh
            </v-icon>
          </div>
        </template>
        <span>{{ 'Recargar cámaras' }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div style="display: inline" @click="toggleShowVideoBars">
            <v-icon size="20" dark v-on="on" class="cursor-pointer ml-3" style="color: white">
              {{ showVideoBars ? 'mdi-eye-off' : 'mdi-eye' }}
            </v-icon>
          </div>
        </template>
        <span>{{ 'Información de cámaras' }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div style="display: inline" @click="toggleFullScreen">
            <v-icon size="20" dark v-on="on" class="cursor-pointer ml-3" style="color: white">
              {{ fullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}
            </v-icon>
          </div>
        </template>
        <span>{{ fullScreen ? 'Salir de pantalla completa' : 'Pantalla completa' }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div style="display: inline" @click="cerrar">
            <v-icon size="20" dark v-on="on" class="cursor-pointer ml-3" style="color: white">
              mdi-close
            </v-icon>
          </div>
        </template>
        <span>Cerrar</span>
      </v-tooltip>
    </div>
    <div
      class="pattern-cross-dots-md"
      style="
        display: flex;
        flex-direction: column;
        overflow: auto;
        background-repeat: repeat;
        background-color: #1e1e1e;
        flex: 0 0 calc(100vh - 31px);
      "
    >
      <!-- <div class="my-grid pa-2" :class="['cam-box' + getCamerasPerRow]"> -->
      <draggable
        tag="div"
        v-model="localCameras"
        class="my-grid pa-2"
        :class="['cam-box' + getCamerasPerRow]"
        :options="{ group: 'cameras', animation: 200 }"
        @end="onDragEnd"
        :move="checkMove"
      >
        <widget-box
          class="cam-box"
          corners="flat"
          v-for="camera in localCameras"
          :key="camera.uniqueId"
          :class="{
            'camera-full-screen': cameraFullScreen === camera.uniqueId,
            'display-none': cameraFullScreen !== camera.uniqueId && cameraFullScreen != null,
          }"
        >
          <template v-slot:body>
            <div style="position: relative; height: 100%; width: 100%">
              <iframe
                class="camdiv"
                :title="camera.devId"
                :src="camera.devData"
                :id="camera.cameraId"
                v-if="camera.servidor === 'legacy' || !camera.servidor"
              ></iframe>

              <camera-hls class="camdiv" :hlsUrl="camera.devData" v-else></camera-hls>

              <div
                @dblclick="cameraFullScreen = cameraFullScreen === null ? camera.uniqueId : null"
                style="
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  left: 0;
                  z-index: 1;
                  user-select: none;
                  height: 80%;
                "
                @mouseenter="cameraHover = camera.uniqueId"
                @mouseleave="cameraHover = null"
              >
                <div
                  :class="
                    cameraFullScreen === camera.uniqueId ||
                    showVideoBars ||
                    cameraHover === camera.uniqueId
                      ? 'video-bar'
                      : 'display-none'
                  "
                >
                  <div class="video-bar-title">
                    {{ camera.name + ' - ' + camera.title }}
                  </div>
                  <div v-if="cameraFullScreen === camera.uniqueId">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div style="display: inline" @click="cameraFullScreen = null">
                          <v-icon size="20" dark class="cursor-pointer ml-3" v-on="on">
                            {{ 'mdi-fullscreen-exit' }}
                          </v-icon>
                        </div>
                      </template>
                      <span>{{ 'Salir de pantalla completa' }}</span>
                    </v-tooltip>
                  </div>
                  <div v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <div style="display: inline" @click="removeCamera(camera.uniqueId)">
                          <v-icon
                            size="20"
                            dark
                            v-on="on"
                            class="cursor-pointer ml-3"
                            style="color: white"
                          >
                            mdi-close
                          </v-icon>
                        </div>
                      </template>
                      <span>Cerrar</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
              <div
                style="
                  position: absolute;
                  bottom: 4px;
                  right: 8px;
                  height: 100px;
                  width: 100px;
                  z-index: 1;
                  user-select: none;
                  opacity: 0.7;
                "
                v-if="camera.type === 'pmi' && camera.isPtz && getUser.attributes.ptz_control"
              >
                <div class="d-flex justify-center">
                  <v-btn
                    color="secondary"
                    fab
                    x-small
                    dark
                    :disabled="moveup"
                    @click="cameraMovement('moveup', camera)"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex justify-space-between">
                  <v-btn
                    color="secondary"
                    fab
                    x-small
                    dark
                    :disabled="moveleft"
                    @click="cameraMovement('moveleft', camera)"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    color="secondary"
                    fab
                    x-small
                    dark
                    :disabled="moveright"
                    @click="cameraMovement('moveright', camera)"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex justify-center">
                  <v-btn
                    color="secondary"
                    fab
                    x-small
                    dark
                    :disabled="movedown"
                    @click="cameraMovement('movedown', camera)"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </div>
              <div
                style="
                  position: absolute;
                  top: 48px;
                  left: 8px;
                  height: 100px;
                  width: 150px;
                  z-index: 2;
                  user-select: none;
                  opacity: 0.8;
                "
                v-if="camera.type === 'pmi' && camera.isPtz && getUser.attributes.ptz_control"
              >
                <div class="d-flex flex-column justify-space-between">
                  <v-btn
                    class="mb-2"
                    color="secondary"
                    fab
                    x-small
                    dark
                    :disabled="zoomin"
                    @click="cameraMovement('zoomin', camera)"
                  >
                    <v-icon>mdi-magnify-plus</v-icon>
                  </v-btn>
                  <v-btn
                    color="secondary"
                    fab
                    x-small
                    dark
                    :disabled="zoomout"
                    @click="cameraMovement('zoomout', camera)"
                  >
                    <v-icon>mdi-magnify-minus</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </template>
        </widget-box>
      </draggable>
      <!-- </div> -->
    </div>
    <div class="cameras-fab ma-2" @click="options = !options">
      <v-icon color="white"> mdi-format-columns </v-icon>
      <transition name="bounce">
        <div
          v-if="options"
          v-click-outside="
            () => {
              options = false;
            }
          "
          style="position: absolute; bottom: 4.5em; margin-left: 0.2em"
        >
          <div class="cameras-fab-option" @click="setCamerasPerRow(2)">
            <v-icon color="white"> mdi-numeric-2-box-outline </v-icon>
          </div>
          <div class="cameras-fab-option" @click="setCamerasPerRow(3)">
            <v-icon color="white"> mdi-numeric-3-box-outline </v-icon>
          </div>
          <div class="cameras-fab-option" @click="setCamerasPerRow(4)">
            <v-icon color="white"> mdi-numeric-4-box-outline </v-icon>
          </div>
          <div class="cameras-fab-option" @click="setCamerasPerRow(5)">
            <v-icon color="white"> mdi-numeric-5-box-outline </v-icon>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState, mapGetters, mapActions } from 'vuex';
import ApiService from '@/util/api.service';
import draggable from 'vuedraggable';
import WidgetBox from './WidgetBox.vue';
import CameraHls from './CameraHLS.vue';

export default {
  components: {
    WidgetBox,
    CameraHls,
    draggable,
  },
  data() {
    return {
      options: false,
      cameraFullScreen: null,
      renderCameras: true,
      cameraHover: null,
      moveup: false,
      movedown: false,
      moveleft: false,
      moveright: false,
      zoomin: false,
      zoomout: false,
      localCameras: []
    };
  },
  computed: {
    ...mapGetters('cameras', ['getCameras', 'getCamerasPerRow']),
    ...mapGetters('auth', ['getUser']),
    ...mapState('cameras', [
      'camerasPerRow',
      'camerasPerRowFullScreen',
      'fullScreen',
      'showVideoBars',
    ]),
    getCamerasPerRow() {
      return this.fullScreen ? this.camerasPerRowFullScreen : this.camerasPerRow;
    },
  },
  watch: {
    // Actualiza localCameras cada vez que getCameras cambie
    getCameras: {
      handler(newVal) {
        console.log(newVal.map((v) => v.uniqueId))
        this.localCameras = [...newVal];
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('cameras', [
      'addCamera',
      'removeCamera',
      'removeAllCameras',
      'setCamerasPerRow',
      'toggleFullScreen',
      'disableFullScreen',
      'toggleShowVideoBars',
      'updateCamerasOrder',
    ]),
    onDragEnd() {
      console.log(...this.localCameras)
      this.updateCamerasOrder(this.localCameras);
    },
    checkMove() {
      return this.cameraFullScreen === null;
    },
    cerrar() {
      this.removeAllCameras();
    },
    centerCamera(camera) {
      this.$parent.setCenter(camera.latitude, camera.longitude, 16);
    },
    forceRerender() {
      this.renderCameras = false;

      this.$nextTick(() => {
        this.renderCameras = true;
      });
    },
    openCamera(item, type) {
      const cameras = Object.keys(item.attributes).filter(
        // eslint-disable-next-line no-restricted-globals
        (key) => key.slice(0, 3) === 'dev' && !isNaN(parseInt(key.slice(3)))
      );
      // const cams = []
      cameras
        .map((key) => item.attributes[key])
        .forEach((cam, key) => {
          const cameraobj = JSON.parse(atob(cam));
          this.addCamera({
            devId: 'dev' + key,
            title: this.getDevTitle(cam),
            servidor: item.attributes?.servidor,
            devData: this.source(cam, type, item),
            cameraId: item.id,
            latitude: item.latitude,
            longitude: item.longitude,
            name: item.name,
            type,
            isPtz: cameraobj.isPtz,
            uniqueId: item.uniqueId,
          });
        });
    },
    openCamerasAround(item, cameraStore) {
      const distance = 0.5;
      const camerasAround = cameraStore.getRecords().filter((camera) => {
        return camera.status === 'up' && this.getDistance(item, camera) <= distance;
      });

      if (camerasAround.length === 0) {
        return window.VMA.showError({
          title: `No se encontraron cámaras ${distance}KM a la redonda`,
          color: 'warning',
        });
      }

      const allCameras = camerasAround.reduce((acc, camera) => {
        return acc.concat(
          Object.keys(camera.attributes)
            .filter((key) => key.slice(0, 3) === 'dev' && _.isNumber(parseInt(key.slice(3))))
            .map((key, index) => ({
              item: _.omit(camera, '_marker'),
              num: index,
              uniqueId: camera.uniqueId,
              camera: camera.attributes[key],
            }))
        );
      }, []);

      allCameras.forEach((it) => {
        const cameraobj = JSON.parse(atob(it.camera));
        this.addCamera({
          devId: 'dev' + it.num,
          devData: this.source(it.camera, 'pmi', it.item),
          cameraId: it.id || it.item.id,
          name: it.item.name,
          latitude: item.latitude,
          longitude: item.longitude,
          servidor: it?.item?.attributes?.servidor,
          type: 'pmi',
          isPtz: cameraobj.isPtz,
          uniqueId: it.uniqueId,
        });
      });
      return true;
    },
    getDistance(mk1, mk2) {
      const R = 6371.071; // Radius of the Earth in kilometers
      const rlat1 = mk1.latitude * (Math.PI / 180); // Convert degrees to radians
      const rlat2 = mk2.latitude * (Math.PI / 180); // Convert degrees to radians
      const difflat = rlat2 - rlat1; // Radian difference (latitudes)
      const difflon = (mk2.longitude - mk1.longitude) * (Math.PI / 180); // Radian difference (longitudes)

      const d =
        2 *
        R *
        Math.asin(
          Math.sqrt(
            Math.sin(difflat / 2) * Math.sin(difflat / 2) +
              Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
          )
        );
      return d;
    },
    getDevTitle(cam) {
      const data = JSON.parse(atob(cam));
      return data.title || data.name;
    },
    source(camera, type, item) {
      const cameraObj = JSON.parse(atob(camera));
      return `/api/v2/video/stream/${cameraObj?.server}/${item.uniqueId}/${cameraObj?.name}`;
    },
    cameraMovement(action, item) {
      this[action] = true;

      ApiService({
        url: '/cameras/ptz/' + action,
        method: 'get',
        params: {
          uniqueid: item.uniqueId,
          camname: item.devId,
        },
      })
        .then(() => {
          this[action] = false;
        })
        .catch((err) => {
          console.error(err);
          this[action] = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.display-none {
  display: none !important;
}

.cameras-container {
  flex: 0 0 400px;
  display: flex;
  position: relative;
  flex-direction: column;

  &.fullscreen {
    flex: 0 0 100%;
  }
}

.camdiv {
  display: block;
  border: unset;
  width: 100%;
  height: 100%;
}

.my-tab-selected {
  border-bottom: 2px solid white;
}

.my-grid {
  display: grid;
  grid-gap: 10px;

  &.cam-box2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.cam-box3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.cam-box4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.cam-box5 {
    grid-template-columns: repeat(5, 1fr);
  }

  .cam-box {
    aspect-ratio: 1 / 0.75;
  }
}

.btn-option {
  position: absolute;
  background: blue;
  aspect-ratio: 1 / 1;
  width: 3vw;
}

.tab-option {
  cursor: pointer;
  padding: 0px 10px 0px 10px;
  flex: 1 1 0;
  white-space: nowrap;
}

.cameras-fab {
  position: absolute;
  right: 0;
  bottom: 0;
  background: var(--v-secondary-base);
  width: 4em;
  aspect-ratio: 1 / 1;
  border-radius: 4em;
  cursor: pointer;
  border: 0.5px solid white;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.cameras-fab-option {
  background: var(--v-secondary-base);
  width: 3.5em;
  aspect-ratio: 1 / 1;
  align-self: end;
  display: flex;
  justify-content: center;
  border-radius: 3.5em;
  margin-bottom: 0.4em;
  cursor: pointer;
  border: 0.5px solid white;
}

.video-bar {
  position: absolute;
  width: 100%;
  height: 25px;
  padding: 0px 5px 0px 5px;
  background-color: rgb(1, 1, 1, 0.6);
  color: white;
  display: flex;
  justify-content: space-between;

  .video-bar-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.camera-full-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 99;
  padding: 0.5em;
}
</style>
